<template>
  <vLoading :active="isLoading"></vLoading>
  <div class="flex flex-col">
    <div class="overflow-x-auto ">
      <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full text-center">
            <thead
              class="border-b bg-neutral-50 font-medium dark:border-neutral-500 dark:text-neutral-800"
            >
              <tr>
                <th scope="col" width="10%" class="px-6 py-4">Platform</th>
                <th scope="col" width="5%" class="px-6 py-4">Type</th>
                <th scope="col" class="px-6 py-4">Url/Version</th>
                <th scope class="text-right" width="10%">
                  <button
                    v-if="editColumn !== null"
                    type="button"
                    class="inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-red-700"
                    @click="clearEdit"
                  >
                    Cancel Edit
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="border-b dark:border-neutral-500"
                v-for="(item, index) in appInfo"
                :key="`${item.Platform}-${item.Type}`"
              >
                <td width="10%" class="px-6 py-4 font-medium">
                  {{ item.Platform }}
                </td>
                <td width="5%" class="px-6 py-4">{{ item.Type }}</td>
                <td class="px-6 py-4">
                  <template v-if="item.Type === 'StoreUrl'">
                    <span v-show="editColumn === null || editColumn !== index">
                      {{ item.Url }}
                    </span>
                    <input
                      v-model="editField.Url"
                      @keydown.esc="clearEdit"
                      type="text"
                      ref="Url"
                      v-show="editColumn === index"
                      class="block min-h-[auto] w-full text-center rounded ring ring-red-300 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none"
                    />
                  </template>
                  <template v-else>
                    <span v-show="editColumn === null || editColumn !== index">
                      {{ item.Version }}
                    </span>
                    <input
                      v-model="editField.Version"
                      type="text"
                      ref="Version"
                      v-show="editColumn === index"
                      @keydown.esc="clearEdit"
                      class="block min-h-[auto] w-full rounded text-center ring ring-red-300 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none"
                    />
                  </template>
                </td>
                <td width="5%">
                  <button
                    v-if="editColumn === index"
                    type="button"
                    class="inline-block rounded bg-green-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-green-700"
                    @click="updateField"
                  >
                    update
                  </button>
                  <button
                    v-else
                    type="button"
                    class="inline-block rounded bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
                    @click="showField(index, item)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const editField = ref({
  Url: '',
  Version: '',
});
const editColumn = ref(null);
const Url = ref(null);
const Version = ref(null);
const appInfo = computed(() => store.getters['appInfo']);
const isLoading = computed(() => store.getters['isLoading']);
const showField = (index, data) => {
  editField.value = { ...data };
  editColumn.value = index;
};
const clearEdit = () => {
  editField.value = {
    Url: '',
    Version: '',
  };
  editColumn.value = null;
};
const updateField = async () => {
  try {
    const result = await store.dispatch('updateAppInfo', editField.value);
    if (result === null) {
    }
  } catch (error) {
    alert(error);
  } finally {
    clearEdit();
  }
};
onMounted(() => {
  store.dispatch('getAppInfo');
});
</script>
